import React from "react";
import { BadgeContainer, StyledBadgeGroup } from "./Badge.styles";
import { Link } from "gatsby";

const Badge = ({ icon, buttonText, headline, to }) => {
  return (
    <Link to={to}>
      <BadgeContainer>
        <i className={`fas ${icon} left-side`} />
        <div className="right-side">
          <h2>{headline}</h2>
          <button className="start-button">
            {buttonText} <i className="fas fa-arrow-circle-right" />
          </button>
        </div>
      </BadgeContainer>
    </Link>
  );
};

export const BadgeGroup = StyledBadgeGroup;
export default Badge;
