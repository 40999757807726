import React, { useState } from "react";
import get from "lodash.get";

import {
  StyledCarousel,
  StyledHeadline,
  CarouselWrapper,
  PaddingButton,
} from "./Carousel.styles";

const Carousel = ({ className, images, headline, hideBorder }) => {
  const [currSlide, setSlide] = useState(0);
  const credit = get(images, [currSlide, "credit"]);
  const stripStatic = path => path.replace("/static", "");
  const ArrowButton = direction => ({ previousSlide, nextSlide }) => {
    const fnToUse = direction === "left" ? previousSlide : nextSlide;
    return (
      <PaddingButton onClick={fnToUse}>
        <i className={`fas fa-angle-${direction}`}></i>
      </PaddingButton>
    );
  };
  return (
    <CarouselWrapper className={className} hideBorder={hideBorder}>
      <StyledCarousel
        wrapAround
        hideBorder={hideBorder}
        afterSlide={setSlide}
        renderCenterLeftControls={ArrowButton("left")}
        renderCenterRightControls={ArrowButton("right")}
      >
        {images.map(({ image, alt }, i) => (
          <img
            key={`${i}-carousel-image`}
            src={stripStatic(image)}
            alt={alt}
            title={alt}
          />
        ))}
      </StyledCarousel>
      {credit ? (
        <div className="credit">
          <a href={credit.url}>
            <i className="fas fa-camera"></i> Photo by {credit.name}
          </a>
        </div>
      ) : null}
      {!hideBorder ? <StyledHeadline>{headline}</StyledHeadline> : null}
    </CarouselWrapper>
  );
};

Carousel.defaultProps = {
  images: [],
  headline: "Let us keep the pests away",
};

export default Carousel;
