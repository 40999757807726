import styled from "styled-components";

export const BadgeContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.red1};
  padding: 10px 0;

  font-size: 16px;
  font-weight: 700;
  color: white;
  width: 100%;
  margin-bottom: 5px;

  i.left-side {
    color: ${({ theme }) => theme.red2};
    font-size: 42px;
    margin-right: 15px;
    flex-basis: 25%;
  }

  h2 {
    color: white;
    font-size: 16px;
    margin: 0 0 5px;
    width: 100%;
  }

  button {
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 700;
    text-align: right;
    width: 100%;
    color: ${({ theme }) => theme.red2};

    &:hover {
      cursor: pointer;
    }
  }
`;

export const StyledBadgeGroup = styled.div`
  a {
    display: block;
    width: 100%;
  }
  ${({ theme }) => theme.media.lessThan("md")`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  `}
`;
