import styled from "styled-components";
import NukaCarousel from "nuka-carousel";

export const StyledCarousel = styled(NukaCarousel)`
  color: #333;

  .slider-control-bottomcenter {
    ${({ hideBorder }) =>
      !hideBorder
        ? `
    bottom: unset;
    top: 10px;
    `
        : ""}
  }

  img {
    height: 250px;
    width: auto;
    object-fit: cover;
  }
`;

export const PaddingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: none;
  border: none;
  opacity: 0.8;
  font-size: 64px;
  transition: all 125ms ease-in;
  color: white;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
    color: black;
  }
`;

export const CarouselWrapper = styled.div`
  display: block;
  position: relative;
  ${({ hideBorder, theme }) =>
    `border: ${hideBorder ? "1px" : "5px"} solid ${theme.red2};`}

  div.credit {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px 15px 0 0;
    font-size: 12px;
    color: white;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);

    a,
    a:visited {
      color: white;
    }

    ${({ theme }) => theme.media.lessThan("md")`
      text-align: center;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
      margin: 15px 0 0 0;
    `}
  }
`;

export const StyledHeadline = styled.h1`
  display: inline-block;
  position: absolute;
  text-align: center;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  padding: 10px 15px;
  margin: 0;
  font-weight: 700;
  font-style: italic;
  color: white;
  background-color: ${({ theme }) => theme.red2};
  border-radius: 6px 6px 0 0;

  ${({ theme }) => theme.media.lessThan("md")`
    border-radius: 0px;
    width: 100%;
  `}
`;
